import React from 'react';

const LongTermPlan = () => {
  const plans = [
    {
      title: "TRANSFORMATIVE CURRICULUM AND PEDAGOGY",
      content: [
        "Advanced Digital Learning Resources: The college is integrating interactive e-books and virtual learning platforms into the curriculum. It is also deploying AI-driven learning platforms to personalize student experiences.",
        "Integration of Values: The college is integrating moral values into the curriculum.",
        "Personalized Learner Support Systems: The college is implementing AI-based tutoring systems that provide real-time feedback. It is also developing individualized learning plans based on student performance data.",
        "Innovative Teaching Methodologies: The college is adopting flipped classroom techniques where students review content at home and engage in interactive activities in class. It is also using gamification strategies to make learning more engaging.",
        "Comprehensive Value-Added Programmes: The college is providing certification courses in AI Tools in Teaching Learning, Interactive Multimedia in Education, Data Management and Analysis for Teachers, Cybersecurity and Online Safety for Teachers, Personality Development, Advanced Public Speaking and Presentation, Holistic Wellness and Yoga, Essential First Aid and Life Support, Comprehensive Health and Wellness, Professional English Communication Skills, Mindfulness and Stress Management.",
        "Research Excellence and Innovation: The college is facilitating faculty research grants for innovative projects. It is also hosting annual research conferences to showcase student and faculty research.",
        "Professional Growth for Faculty: The college is providing faculty members with access to advanced online training modules. It is also organizing peer review sessions and collaborative teaching workshops.",
        "Active Participation in Knowledge Exchange: The college is establishing partnerships with international, national, and state educational institutions for faculty exchange programmes. It is also hosting guest lectures and seminars by domain experts.",
        "Promotion of Lifelong Learning: The college is offering online courses and certifications for alumni and current students. It is also organizing career development workshops, seminars, and webinars on emerging trends."
      ]
    },
    {
      title: "ENRICHING STUDENT LIFE AND ENGAGEMENT",
      content: [
        "Enhanced Student Support Services: The college is providing career counselling sessions and academic advising. It is also establishing peer mentoring programmes to support new students.",
        "Community Engagement and Outreach: The college is organizing local community service projects such as environmental clean-ups. It is also hosting outreach programs to support underprivileged children.",
        "Digital Admission and Administrative Systems: The college is implementing an online admission portal for a seamless application process. It is also upgrading administrative software for efficient record-keeping of students’ performance.",
        "National and Global Awareness Campaigns: The college is conducting awareness drives on all national issues. It is also organizing events to promote global cultural understanding and cooperation.",
        "Strengthened Community Service Initiatives: The college is partnering with Government / NGOs for collaborative community projects. It is also setting up a volunteer centre for student engagement in social causes.",
        "Cultural and Artistic Expression: The college is hosting annual cultural festivals, talent shows, and art exhibitions. It is also providing opportunities for students to participate in drama, music, and dance performances.",
        "Student Clubs and Organizations: The college is supporting the formation of diverse student clubs related to interests and career goals. It is also providing resources and guidance for club activities and events.",
        "Cultivating Educators for Sustainable Farming: The college is actively encouraging students to study organic farming as part of their teacher education. This initiative is fostering a deeper understanding of agriculture, empowering future teachers to contribute to both the agricultural sector and the community."
      ]
    },
    // Add more sections here following the same structure
    {
      title: "ADVANCED INFRASTRUCTURE AND FACILITIES",
      content: [
        "Resource Optimization and Upgradation: The college is upgrading laboratory equipment with the latest technology. It is also optimizing classroom layouts for better learning environments.",
        "Modern Laboratory and Tech Upgrades: The college is installing LED TVs in all the classrooms and LED projectors. It is also enhancing Wi-Fi infrastructure for improved connectivity.",
        "Eco-Friendly Campus Development: The college is implementing waste recycling programmes and green energy solutions. It is also creating more green spaces and gardens on campus."
      ]
    },
    {
      title: "FACULTY EMPOWERMENT AND ADVANCEMENT",
      content: [
        "Advanced Qualification Support: The college is supporting faculty in pursuing higher degrees and is also offering resources for research and professional certifications.",
        "Research Opportunities: The college is assisting faculty in publishing research papers and providing opportunities for them to undertake projects that benefit society.",
        "Faculty Exchange Programs: The college is facilitating faculty exchange programs to enrich educational practices and foster cross-cultural understanding. Additionally, it is hosting scholars and researchers for collaborative projects.",
        "Participation in High-Impact Seminars, Workshops and Conferences: The college is sponsoring faculty to attend leading seminars, workshops, and conferences. It is also supporting their presentation of research papers at these events.",
        "Comprehensive Faculty Welfare Initiatives: The college is offering wellness programmes and support services for faculty. It is also organizing programmes on work-life balance and career development.",
        "Seed Money for Research Initiatives: The college provides seed money to promote research activities. This financial support helps faculty initiate and advance their research projects, explore new ideas, conduct experiments, and contribute to academic knowledge. By offering this funding, the college fosters a vibrant research culture and encourages innovative exploration among its staff.",
        "Scholarly Publication and Research: The college is going to launch a research journal for the publication of academic work. It is also organizing research workshops to develop publishing skills.",
        "Collaborative Research Opportunities: The college is initiating joint research projects with various educational institutions. It is also setting up research labs for student-led investigations."
      ]
    },
    {
      title: "STUDENT SUCCESS AND DEVELOPMENT",
      content: [
        "Competitive Exam Preparation and Coaching: The college is offering preparatory classes for competitive exams. It is also providing access to online resources and practice tests.",
        "Career Development and Employability: The college is organizing career fairs and employer networking events. It is also providing internship opportunities and job placement support.",
        "Equal Opportunity Initiatives: The college is implementing policies to ensure inclusivity and diversity.",
        "Sports and Extracurricular Excellence: The college is hosting inter-college sports tournaments. It is also supporting student participation in district, state, national, and international competitions.",
        "Mandatory Online Learning Integration: The college is requiring the completion of online courses as part of the curriculum. It is also offering a range of MOOCs for skill enhancement.",
        "Enhanced Mentor-Mentee Relationships: The college is establishing a formal mentorship program linking students with faculty mentors. It is also organizing regular mentoring sessions."
      ]
    },
    {
      title: "STRATEGIC INSTITUTIONAL DEVELOPMENT",
      content: [
        "Establishment of a Research Hub: The college is going to set up a dedicated research centre to support faculty and student research.",
        "Launch of M.Ed. Programme: The college has the intention to start a Master of Education (M.Ed.) programme. It is also developing specialized tracks within the program.",
        "Development of an Incubation Centre: The college is creating an incubation centre focused on teaching and learning innovations. It is also providing resources and mentorship for educational startups.",
        "Institution Innovation Council (IIC): The college is going to launch an IIC to foster innovation and entrepreneurship among students and faculty.",
        "Academic Publication Unit: The college is going to start a publication unit for producing academic journals and research papers. It is also facilitating peer review and editorial processes.",
        "Aiming for Top-Tier Status: The college is implementing strategies to achieve top-tier status in national rankings. It is also focusing on continuous improvement and excellence in all aspects."
      ]
    },
    {
      title: "ALUMNI ENGAGEMENT AND DEVELOPMENT",
      content: [
        "Strengthening the Alumni Association: The college is revitalizing the alumni association with regular events and networking opportunities. It is also creating an online platform for alumni engagement.",
        "Alumni Mentorship Programs: The college is launching mentorship programs where alumni guide current students. It is also providing opportunities for alumni to share their experiences and insights.",
        "Alumni-Student Collaborative Projects: The college is encouraging collaborative projects between alumni and current students. It is also supporting internships and job placements through alumni connections.",
        "Regular Alumni Events and Reunions: The college is hosting annual alumni reunions and special events. It is also organizing themed gatherings to foster a strong alumni community.",
        "Alumni Contributions to Institutional Development: The college is seeking alumni support for fundraising and development initiatives. It is also involving alumni in advisory committees and strategic planning."
      ]
    }
  ];

  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center mt-3">
          <div className="col-md-12 heading-section ftco-animate text px-4">
            <h2 className="mb-4 text-success text-center">
              Long Term Plan<span className="text-warning">  of  KCE</span>
            </h2>
            <p>
              It creates a roadmap to align activities and resources with long-term goals. By 2030, Kaamadhenu College of Education will attain a premier status due to the following existing plans:
            </p>
          </div>
        </div>
        {plans.map((plan, index) => (
          <div key={index} className="row">
            <div className="col-md-12 order-md-first">
              <div className="text px-4 py-4 ftco-animate">
                <h3 className="mb-4 text-primary">{plan.title}</h3>
                <ul>
                  {plan.content.map((item, idx) => (
                    <li key={idx} className="mb-2">
                      <strong>{item.split(":")[0]}:</strong>
                      {item.split(":")[1]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LongTermPlan;
