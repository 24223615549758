import ImageSlider from './ImageSlider'; 


import TransportationImage1 from '../assets/images/facilities/Transportation-1.jpg';
import TransportationImage2 from '../assets/images/facilities/Transportation-2.jpg';


const images = [
    { src: TransportationImage1, altText: 'Transportation-1' },
    { src: TransportationImage2, altText: 'Transportation-2' },
  
];
const Transportation = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Transportation</span> 
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            Kaamadhenu College of Education offers a well-organized transportation service to facilitate easy access for student teachers from various towns. The college operates buses covering routes from:
                            </p>
                            <ol>
                            <li>Coimbatore – Kalapatty & Saravanampatty</li>
                            <li>Tirupur</li>
                            <li>Avinashi</li>
                            <li>Perundurai</li>
                            <li>Annur</li>
                            <li>Mettupalayam</li>
                            <li>Gobichettipalayam</li>
                            <li>Sathyamangalam</li>
                            <li>Nambiyur</li>
                            <li>Puliampatty</li>
                            <li>Anthiyur</li>
                        </ol>
                            <p>
                            These routes ensure safe and efficient travel, supporting students in reaching the campus conveniently and punctually.

      </p>

                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default Transportation;
