// src/components/LogoSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.jpg'; // Adjust the path to your logo image

const LogoSection = () => {
  return (
    <div className="container d-flex align-items-center">
      <Link to="/" className="d-flex align-items-center">
        <img src={logo} width="125" height="125" alt="KAAMADHENU COLLEGE OF EDUCATION" />
        <div className="ml-3">
          <span className="navbar-brand fs-2 font-weight-bold">
            KAAMADHENU COLLEGE OF EDUCATION
          </span>
          <p className="text-black-75 mb-0 affiliation-text">
            Recognized by National Council for Teacher Education <strong>&amp;</strong> Affiliated to the Tamil Nadu Teachers Education University
          </p>
          <p className="text-black-75 mb-0 affiliation-text">
            Sathyamangalam – 638503, Erode DT.
          </p>
        </div>
      </Link>
    </div>
  );
};

export default LogoSection;
