import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/ImageSlider.css';

const ImageSlider = ({ images, interval = 3000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, interval);

        return () => clearInterval(slideInterval);
    }, [images.length, interval]);

    return (
        <div className="wrap-about">
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`imageComponent ${currentIndex === index ? 'active' : ''}`}
                >
                    <img src={image.src} alt={image.altText} />
                </div>
            ))}
        </div>
    );
};

ImageSlider.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            altText: PropTypes.string.isRequired
        })
    ).isRequired,
    interval: PropTypes.number
};

export default ImageSlider;
