import ChairmanSection from './ChairmanSection'
import SecretarySection from './SecretarySection'
import JoinSecretarySection from './JoinSecretarySection'

const ManagementSection = () => {
  const hideElem = true; // or false, or a condition based on your logic

  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb"> 
      <div className="container">
        <ChairmanSection />
        <SecretarySection hideElem={hideElem} />
        <JoinSecretarySection hideElem={hideElem} />
      
        </div>
  
    </section>
  );
};

export default ManagementSection;
