
const StudentCouncil = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb">
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Student </span> Council
                        </h2>
                    </div>
                </div>
              
                <div className="row mt-3">
                    <div className="col-md-12 order-md-first">
                        <div className="text px-4">
                            <p>
                             The Student Council at Kaamadhenu College of Education actively represents the student community and fosters a glowing campus life. Guided by leadership, responsibility, and community service, the Council enhances the overall student experience.
                            </p>
                            <h3 className="mb-4 text-primary">Roles and Responsibilities:</h3>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Student Representation: The Council voices students’ concerns, ideas, and suggestions to the college administration to ensure a collaborative environment.</li>
                                <li className="list-group-item">Event Organization: The Council plans and executes various cultural, academic, and social events.</li>
                                <li className="list-group-item">Community Engagement: The Council initiates awareness campaigns, social outreach programs, and volunteer activities.</li>
                                <li className="list-group-item">Leadership Development: By participating in the Council, students build essential leadership skills, including communication, problem-solving, and teamwork.</li>
                            </ul>
                        </div>
                    </div>

                    
                </div>  

                

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                            <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">Youth Red Cross </span> (YRC)
                            </h2>
                            <p>
                                The Youth Red Cross (YRC) at Kaamadhenu College of Education fosters a spirit of humanitarian service among student teachers. By participating in health, safety, and disaster response activities, YRC members actively contribute to the well-being of the college and the wider community. Through initiatives like blood donation drives and health awareness programs, the YRC nurtures responsible and compassionate citizens dedicated to making a positive impact on society.
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                            <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">Red Ribbon Club </span> (RRC)
                            </h2>
                            <p>
                                The Red Ribbon Club (RRC) at Kaamadhenu College of Education is dedicated to raising awareness about HIV/AIDS and promoting healthy lifestyle choices among student teachers. The club actively engages in campaigns, workshops, and community outreach programmes to educate others on the importance of safe practices, prevention, and the fight against stigma. Through its initiatives, the RRC empowers student teachers to take responsibility for their health and contribute to creating a more informed and supportive community.
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                            <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">Eco Club </span> (EC)
                            </h2>
                            <p>
                                The Eco Club at Kaamadhenu College of Education is committed to promoting environmental awareness and sustainable practices among student teachers. The club organizes activities such as tree planting, clean-up drives, and workshops on conservation to inculcate a sense of responsibility towards the environment. By participating in the Eco Club, student teachers actively contribute to creating a greener campus and a more sustainable future.
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />

                <div className="row mt-3">
                    <div className="col-md-12t">
                        <div className="text px-4">
                            <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">Child Rights Club </span> (CRC)
                            </h2>
                            <p>
                                The Child Rights Club at Kaamadhenu College of Education is focused on promoting and safeguarding children’s rights. The club conducts awareness programmes, campaigns, and educational initiatives to highlight the importance of child protection and well-being. Members of the Child Rights Club actively work to ensure that children have a safe, nurturing environment, and they are committed to making a positive impact on the lives of young people.
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                            <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">Tamil Literacy Forum </span> (TLF)
                            </h2>
                            <p>
                                The Tamil Literacy Forum (TLF) at Kaamadhenu College of Education is committed to celebrating and promoting the Tamil language and its literary heritage. The forum organizes a variety of events, including debates in Tamil, meetings with renowned writers, poetry readings, and literary discussions. These activities offer the opportunity for student teachers to engage deeply with Tamil literature and culture. It fosters a greater appreciation and understanding of their linguistic roots.
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                            <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">National Service Scheme </span> (NSS)
                            </h2>
                            <p>
                                The National Service Scheme (NSS) at Kaamadhenu College of Education is dedicated to fostering social responsibility and community service among student teachers. Through a range of activities including volunteer work, community outreach programs, and social awareness campaigns, NSS encourages students to contribute positively to society. By participating in NSS, students gain valuable experience in leadership, teamwork, and social engagement, making a meaningful impact on both their personal development and the well-being of others.
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                            <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">Women Empowerment Club </span> (WEC)
                            </h2>
                            <p>
                                The Women Empowerment Club at Kaamadhenu College of Education is dedicated to promoting gender equality and empowering women through education and advocacy. The club organizes workshops, seminars, and awareness campaigns on topics such as women's rights, leadership, and personal development. By fostering a supportive environment, the Women Empowerment Club encourages student teachers to advocate for women’s issues and work towards creating a more equitable society.
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                            <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">Guidance and Counselling Cell </span> (GCC)
                            </h2>
                            <p>
                                The Guidance and Counselling Cell at Kaamadhenu College of Education provides comprehensive support for student teachers’ personal, academic, and career development. The cell offers individual and group counselling sessions, career guidance, and assistance with higher education planning. It also conducts workshops on stress management, study skills, and personal growth. By addressing academic and emotional needs and providing valuable insights for career and higher education choices, the Guidance and Counselling Cell helps student teachers navigate their educational journey and achieve their full potential.
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />


                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                            <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">Sports</span> 
                            </h2>
                            <p>
                            Kaamadhenu College of Education prioritizes physical fitness and well-being as integral to student life. Our sports program offers a wide range of activities designed to enhance health, teamwork, and competitive spirit.
                            </p>
                            <h3 className="mb-4 text-primary">Facilities and Activities:</h3>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Playgrounds: We maintain well-equipped grounds for outdoor sports such as kabadi,kho-kho, ballbatminton, throwball, football, and volleyball.
                                </li>
                                <li className="list-group-item">Indoor Sports: Our facilities support indoor games including carrom, table tennis, and chess.
                                </li>
                                <li className="list-group-item">Fitness Center: Our fully equipped gym aids students in fitness and strength training.
                                </li>
                                <li className="list-group-item">Inter-College Competitions: Students have opportunities to compete in sports events and tournaments organized at the inter-college level.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />
                </div>
                </section>
    );
};

export default StudentCouncil;
