

import ImageSlider from './ImageSlider'; 


import MultipurposeHallImage from '../assets/images/facilities/SeminarHall.jpg';

const images = [
    { src: MultipurposeHallImage, altText: 'Multipurpose Hall' },
   
];

const MultipurposeHall = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Multipurpose</span>  Hall
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            The Multipurpose Hall at Kaamadhenu College of Education is a flexible and dynamic space designed to accommodate a variety of events and activities. With a large seating capacity, it supports academic functions, cultural events, and other gatherings. The hall features adaptable seating arrangements and modern audio-visual equipment, including projectors and sound systems, to facilitate diverse events. Its versatile design enables it to transform seamlessly between different types of functions, from seminars and workshops to performances and exhibitions.

</p>
                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default MultipurposeHall;
