
const SecretarySection = ({hideElem}) => { 
  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb"> 
      <div className="container">
        {!hideElem && (
          <div className="row justify-content-center mt-3 pb-4">
            <div className="col-md-12  heading-section ftco-animate text px-4">
              <h2 className="mb-4 text-success text-center">
                <span className="text-warning">Management </span> Team
              </h2>
            </div>
          </div>
        )}

        <div className="row mt-3">
          <div className=" col-md-12 order-md-first wrap-about">
            <div className="text px-4">
              <h2 className="mb-4 text-primary"> Secretary’s Message </h2>
              <p>
              Welcome to Kaamadhenu College of Education, where excellence meets inspiration! As the Secretary, I am thrilled to invite you to be a part of our vibrant and nurturing environment dedicated to quality teacher education. Here, you will benefit from innovative programs, modern facilities, and a supportive community that fosters both personal and professional growth. We are committed to providing an enriching experience that equips you with the skills and knowledge to make a meaningful impact in education. At Kaamadhenu, your journey will be marked by passion, innovation, and transformative learning. Join us, unleash your limitless potential and begin your amazing journey as an influential teacher.
              </p>
              <h5 className='font-weight-bold text-right'>Ms. P. Arunthathi. <span className="qualification">B.E., M.S.,</span> <br />Secretary</h5>
              
             </div>
          </div>
        </div>  
      </div>  
      <hr className="custom-hr"/>
    </section>
  );
};

export default SecretarySection;
