
const Scholarships = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                           Scholarships
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-12 order-md-first">
                        <div className="text px-4">
                            <h2 className="mb-4 text-primary">SC/ST Government Scholarships</h2>
                            <p>
                            Kaamadhenu College of Education offers support to students from Scheduled Castes (SC) and Scheduled Tribes (ST) through state government scholarship scheme. This scholarship aims to provide financial assistance and promote higher education among marginalized communities.
                            </p>
                            <p><strong>Post-Matric Scholarship:</strong> For students pursuing B.Ed. course, covering tuition fees, accommodation, and more.
                            </p>
                            <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-warning text-center">
                           Other Scholarships
                        </h2>   
                    </div>
                             <p>
                           <h2 className="text-primary text-initial">Scholarship for Meritorious B.Ed. Students:</h2> 
                           <p> We provide a fee concession as a scholarship to meritorious students applying for the B.Ed. programme. This scholarship supports outstanding candidates by reducing their financial burden and encouraging academic excellence.</p>
                            <p><strong>Eligibility:</strong> Demonstrate exceptional academic performance.</p>
                            <p><strong> Benefit:</strong> Receive a reduction in tuition fees.</p>
                            <p> <strong>Application</strong>: Automatically considered during the admission process.</p>
                            <h2 className="text-primary">Need-Based Scholarships: </h2>
                            Planned for students who require financial support to pursue their studies. These scholarships are aimed at reducing the financial burden on students from economically disadvantaged backgrounds.


                            </p>
                        </div>
                    </div>

                    
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default Scholarships;
