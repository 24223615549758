import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./assets/css/animate.css";
import "./assets/css/style.css";
import './assets/css/Header.css';
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'animate.css';
import Header from "./components/Header";
import ImageCarousel from './components/ImageCarousel';
import Footer from './components/Footer';
import Home from './components/Home'; 
import VisionMission from './components/VisionMission';
import AboutSection from './components/AboutSection';
import ManagementSection from './components/ManagementSection'
import ChairmanSection from './components/ChairmanSection'
import SecretarySection from './components/SecretarySection'
import JoinSecretarySection from './components/JoinSecretarySection'
import PrincipalDesk from './components/PrincipalDesk'
import CoursesOffered from './components/CoursesOffered'
import Admission from './components/AdmissionSection'
import FacultySection from './components/FacultySection'
import AdministrativeSection from './components/AdministrativeSection'
import Library from './components/Library'
import PsychologyLab from './components/PsychologyLab'
import Supw from './components/Supw'
import MathLab from './components/MathLab'
import IntegratedScienceLab from "./components/IntegratedScienceLab"
import LanguageLab from "./components/LanguageLab"
import ComputerLab from "./components/ComputerLab"
import EduTechLab from "./components/EduTechLab"
import Transportation from "./components/Transportation"
import Hostel from "./components/Hostel"
import Cafeteria from "./components/Cafeteria"
import Classrooms from "./components/Classrooms"
import SeminarHall from "./components/SeminarHall"
import MultipurposeHall from "./components/MultipurposeHall"
import StudentCouncil from "./components/StudentCouncil"
import PlacementCell from "./components/PlacementCell"
import Iqac from "./components/Iqac"
import LongTermPlan from "./components/LongTermPlan"
import CollegePolicy from "./components/CollegePolicy"
import BestPractices from "./components/BestPractices"
import AcademicCalendar from "./components/AcademicCalendar";
import Plos from "./components/Plos";
import Aqar from "./components/Aqar"
import Scholarships from "./components/Scholarships";
import ValueAddedProgrammes from "./components/ValueAddedProgrammes"
import AlumniAssociation from "./components/AlumniAssociation"
import GuidenceSection from "./components/GuidenceSection";
import CollegeNewsletter from "./components/CollegeNewsletter";
import UpcomingEvents from "./components/UpcomingEvents";
import Gallery from "./components/Gallery";
import WhatsAppIcon from './components/WhatsAppIcon'; 

AOS.init();

function App() {
  return (
    <Router basename={"/"}>
      <Header/>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<div>  <ImageCarousel /><VisionMission /><AboutSection /><ManagementSection /><CoursesOffered /><Admission /></div>} />
          <Route path="visionMission" element={<VisionMission />} />
          <Route path="aboutSection" element={<AboutSection />} />
          <Route path="management" element={<ManagementSection />} />
          <Route path="chairmanMessage" element={<ChairmanSection />} />
          <Route path="secretaryMessage" element={<SecretarySection />} /> 
          <Route path="jointSecretaryMessage" element={<JoinSecretarySection />} /> 
          <Route path="principalDesk" element={<PrincipalDesk />} /> 
          <Route path="course" element={<CoursesOffered />} /> 
          <Route path="admission" element={<Admission />} /> 
          <Route path="faculty" element={<FacultySection />} />
          <Route path="administration" element={<AdministrativeSection />} />
          <Route path="library" element={<Library />} />
          <Route path="psychologyLab" element={<PsychologyLab />} />
          <Route path="supw" element={<Supw />} />
          <Route path="mathLab" element={<MathLab />} />
          <Route path="integratedScienceLab" element={<IntegratedScienceLab />} />
          <Route path="languageLab" element={<LanguageLab />} />
          <Route path="edutechLab" element={<EduTechLab />} />
          <Route path="transportation" element={<Transportation />} />
          <Route path="hostel" element={<Hostel />} />
          <Route path="cafeteria" element={<Cafeteria />} />
          <Route path="computerLab" element={<ComputerLab />} />
          <Route path="classrooms" element={<Classrooms />} />
          <Route path="seminarHall" element={<SeminarHall />} />
          <Route path="multipurposeHall" element={<MultipurposeHall />} />
          <Route path="studentCouncil" element={<StudentCouncil />} />
          <Route path="placementCell" element={<PlacementCell />} />
          <Route path="iqac" element={<Iqac />} />
          <Route path="longTermPlan" element={<LongTermPlan />} />
          <Route path="collegePolicy" element={<CollegePolicy />} />
          <Route path="bestPractices" element={<BestPractices />} />
          <Route path="academicCalender" element ={<AcademicCalendar/>}/>
          <Route path="plos" element={<Plos />} />
          <Route path="aqar" element={<Aqar />} />
          <Route path="scholarships" element={<Scholarships />} />
          <Route path="valueAddedPrograms" element={<ValueAddedProgrammes />} />
          <Route path="alumini" element={<AlumniAssociation />} />
          <Route path="guidence" element={<GuidenceSection/>}/>
          <Route path="collegeNews" element={<CollegeNewsletter />} />
          <Route path="upcomingEvents" element={<UpcomingEvents />} />
          <Route path="gallery" element={<Gallery />} />
       
          
          {/* Add other routes if needed */}
        </Route>
      </Routes>
      <WhatsAppIcon phoneNumber="918012521226" /> {/* College WhatsApp number */}
     
      <Footer />
    </Router>
  );
}

export default App;
