import React from 'react';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../assets/css/CustomGallery.css';
// Import images from the 2023-24 folder
const importImages2023 = (requireContext) => requireContext.keys().map(requireContext);
const images2023 = importImages2023(require.context('../assets/images/Gallery/Photos/2023-24', false, /\.(png|jpe?g|svg|JPG)$/));

// Import images from the AnnualDay folder
const importImagesAnnualDay = (requireContext) => requireContext.keys().map(requireContext);
const imagesAnnualDay = importImagesAnnualDay(require.context('../assets/images/Gallery/Photos/AnnualDay', false, /\.(png|jpe?g|svg|JPG)$/));

// Import images from the others folder
const importImagesOther = (requireContext) => requireContext.keys().map(requireContext);
const imagesOthers = importImagesOther(require.context('../assets/images/Gallery/Photos/Others', false, /\.(png|jpe?g|svg|JPG)$/));

// Combine images from both folders
const allImages = [ ...imagesAnnualDay,...imagesOthers, ...images2023];

// Generate image objects for the gallery
const generateImageObjects = (images) => {
  return images.map((image, index) => ({
    original: image,
    thumbnail: image,
    description: `Image ${index + 1}`,
  }));
};

const imageObjects = generateImageObjects(allImages);

const MyGallery = () => {
  return (
    <section className="ftco-section ftco-no-pb">
<div className="container">
<div className="row justify-content-center">
    <div className="col-md-12  heading-section ftco-animate text px-4">
      <h2 className="mb-4 text-success text-center">
        Gallery
      </h2>   
    </div>
  </div>
    
  <div className="row">
    <div className=" col-md-12 ">
      <div className="text">
              <Gallery items={imageObjects}
              autoPlay={true} // Enable auto-play
              showPlayButton={true} // Optionally hide the play button
              showThumbnails={true} // Optionally show thumbnails
              slideInterval={4000} // Set the interval between slides (in milliseconds)
           
              />
        </div>
    </div>

  
  </div>  
  </div>
  {/* <hr className="custom-hr"/> */}
    </section>
  );
};
export default MyGallery;
