
const IQACTable = () => {
  const members = [
    { id: 1, name: "Dr. S. Ramprabhu", designation: "Principal, Kaamadhenu College of Education", role: "Chairperson" },
    { id: 2, name: "Ms. P. Arunthathi", designation: "Secretary, Kaamadhenu College of Education", role: "Management Member" },
    { id: 3, name: "Dr. P. Malar Selvi", designation: "Joint Secretary, Kaamadhenu College of Education", role: "Management Member" },
    { id: 4, name: "Ms. K. Kamalaveni", designation: "Assistant Professor, Kaamadhenu College of Education", role: "Coordinator" },
    { id: 5, name: "Dr. S. Rajaguru", designation: "Principal (Retired), Sri Ramakrishna Mission Vidyalaya College of Education, Coimbatore", role: "Educationist" },
    { id: 6, name: "Dr. V. J. Nirmala", designation: "Dean, Kaamadhenu Educational Institutions", role: "Expert" },
    { id: 7, name: "Dr. S. Manikandan", designation: "Assistant Professor, Government College of Education, Coimbatore", role: "Expert" },
    { id: 8, name: "Mr. C. Muthu Sakthivelan", designation: "Secretary, Kongu Public School, Kunnathur", role: "Stakeholder" },
    { id: 9, name: "Dr. A. Samiappan", designation: "Chairman, Saru Educational Institutions", role: "Stakeholder" },
    { id: 10, name: "Ms. S.L. Mahendra Bharathi", designation: "Assistant Professor, Kaamadhenu College of Education", role: "Member" },
    { id: 11, name: "Ms. S. Bharathi", designation: "Assistant Professor, Kaamadhenu College of Education", role: "Member" },
    { id: 12, name: "Mr. R. Rajkumar", designation: "Assistant Professor, Kaamadhenu College of Education", role: "Member" },
    { id: 13, name: "Ms. R. Priyabharathi", designation: "Alumnus", role: "Member" },
    { id: 14, name: "Ms. M. S. Kanishkha", designation: "B.Ed. Student", role: "Member" },
    { id: 15, name: "Ms. R. Sudha", designation: "Office Admin, Kaamadhenu College of Education", role: "Member" },
  ];

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">IQAC Members - Kaamadhenu College of Education</h2>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => (
              <tr key={member.id}>
                <td>{index + 1}</td>
                <td>{member.name}</td>
                <td>{member.designation}</td>
                <td>{member.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IQACTable;
