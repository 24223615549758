
const CollegeNewsletter = () => {
  return (
    <section className="ftco-section ftco-no-pb">
<div className="container">
<div className="row justify-content-center pb-4">
    <div className="col-md-12  heading-section ftco-animate text px-4">
      <h2 className="mb-4 text-success text-center">
        <span className="text-warning">College </span> Newsletter
      </h2>   
    </div>
  </div>
    
  <div className="row mt-3">
    <div className=" col-md-12 order-md-first wrap-about">
      <div className="text px-4">
    
              <p>  TBD </p>
        </div>
    </div>

  
  </div>  
  </div>
  {/* <hr className="custom-hr"/> */}
    </section>
  );
};

export default CollegeNewsletter;
