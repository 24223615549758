

import ImageSlider from './ImageSlider'; 


import ClassroomImage from '../assets/images/facilities/MathsLab.jpg';

const images = [
    { src: ClassroomImage, altText: 'Class Rooom' },
   
];
const Classrooms = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Classrooms</span> 
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            Kaamadhenu College of Education provides modern and well-equipped classrooms designed to enhance the learning experience. Each classroom features comfortable, ergonomic furniture and incorporates advanced technology, including LED projectors and televisions, to support interactive and multimedia-based teaching. The spacious classrooms promote a productive learning environment.
                            </p>
                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default Classrooms;
