import AdmissionOpenImage from '../assets/images/AdmissionOpen.jpg'; // Adjust the path as needed

const CoursesOffered = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Courses </span> Offered
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <h2 className="mb-4 text-primary">Two-Year B.Ed. Programme</h2>
                            <p>
                                Kaamadhenu College of Education offers a two-year B.Ed. degree programme that blends coursework with practical teaching experience. The TNTEU curriculum focuses on advanced teaching methods and value-based education, preparing graduates to excel as skilled and compassionate teachers.
                            </p>
                            <p>
                                <strong>Click here: </strong>
                                <a href="/assets/pdf/Rules.pdf" download className="text-primary mt-3">
                                    Regulations for the Two-Year B.Ed. degree programme
                                </a>
                            </p>
                            <p>
                                <strong>Click here: </strong>
                                <a href="/assets/pdf/Syllabus.pdf" download className="text-primary mt-3">
                                    Syllabus for the Two-Year B.Ed. degree programme
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="col-md-4 order-md-last wrap-about">
                        <div>
                            <img 
                                src={AdmissionOpenImage} 
                                alt="Admission Open for B.Ed. Programme" 
                                className="img-fluid" 
                                width="100%" 
                                height="auto" 
                            />
                        </div>
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default CoursesOffered;
