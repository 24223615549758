import ImageSlider from './ImageSlider'; 


import ComputerScineceLab1 from '../assets/images/facilities/ComputerScineceLab-1.jpg';
import ComputerScineceLab2 from '../assets/images/facilities/ComputerScineceLab-2.jpg';


const images = [
    { src: ComputerScineceLab1, altText: 'Psychology Lab-1' },
    { src: ComputerScineceLab2, altText: 'Psychology Lab-2' },
  
];
const ComputerLab = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Computer</span> Lab
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            The Computer Lab at Kaamadhenu College of Education equips student teachers with modern computers and the latest software for a range of educational and technological activities. The lab features advanced hardware and software, including programming tools and educational applications. Student teachers gain hands-on experience with digital resources, enhancing their skills in computer science, data analysis, and digital communication. 
                            </p>
                         </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                     
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default ComputerLab;
