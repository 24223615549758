
import ImageSlider from './ImageSlider'; 


import LanguageLab from '../assets/images/facilities/LanguageLab.jpg';

const images = [
    { src: LanguageLab, altText: 'LanguageLab' },
   
];


const EduTechLab = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Educational  </span>Technology <span className="text-warning">Lab  </span>
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            The Educational Technology Lab at Kaamadhenu College of Education is designed to equip student teachers with hands-on experience in utilizing modern technological tools for effective teaching and learning. The lab features a wide range of advanced technology to support innovative instructional methods and enhance educational practices.
                            </p>
                            <p><strong>Technological Resources:</strong></p>
                            <ul>
                                <li>Interactive Whiteboard</li>
                                <li>LCD & LED Projector and Screen</li>
                                <li>Computer and Laptop</li>
                                <li>Audio System (Microphones and Speakers)</li>
                                <li>Document Camera</li>
                                <li>Digital Camera and Camcorder</li>
                                <li>Educational Software</li>
                                <li>Mobile Phone & Tablet</li>
                                <li>Overhead Projectors (OHP)</li>
                                <li>Slide Projector</li>
                                <li>Television (TV)</li>
                                <li>Internet with Wi-Fi</li>
                                <li>Teaching Aids</li>
                            </ul>

                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default EduTechLab;
