import React from 'react';
import HeaderTop from './HeaderTop';
import LogoSection from './LogoSection';
import Navbar from './Navbar';

const Header = () => {
  return (
      <header className="header">
      <HeaderTop/>
      <LogoSection />
      <Navbar />
    </header>
  );
};

export default Header;
