

import ImageSlider from './ImageSlider'; 


import HostelImage from '../assets/images/facilities/SeminarHall.jpg';

const images = [
    { src: HostelImage, altText: 'Hostel' },
   
];
const Hostel = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Hostel</span> 
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            Kaamadhenu College of Education provides well-maintained hostel facilities for both male and female student teachers, ensuring a comfortable and supportive living environment. The hostels are equipped with essential amenities to cater to the needs of student teachers and promote a positive residential experience.
                            </p>
                            <p>
                            <strong> Food: </strong>The hostels offer nutritious and balanced meals prepared in hygienic kitchens. Regular menu reviews and feedback mechanisms are in place to accommodate student teachers’ needs and preferences. </p>
                            <p>
                            <strong> Cleanliness:</strong> Maintaining a clean and hygienic environment is a top priority. The hostels are cleaned regularly, with a dedicated housekeeping team ensuring that common areas, dormitories, and bathrooms are kept in pristine condition. Regular inspections help uphold high standards of cleanliness.
                            </p>
                            <p>
                           <strong> Safety: </strong>The safety and security of students are paramount. The hostels are equipped with security systems, including CCTV cameras and secure entry points. Experienced and vigilant wardens oversee hostel operations and address any concerns, providing a safe and supportive environment for all residents.
                            </p>

                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default Hostel;
