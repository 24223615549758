import React, { useState, useEffect } from "react";

function LatestNews() {
  const [newsItems, setNewsItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // update latest news in newsItems.json and upload into your server 
    //and Replace with your actual URL Ex: https://kcesathy.ac.in/newsItems.json
    fetch('https://neinus.com/kcesathy/newsItems.json')  // Replace with the actual URL
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setNewsItems(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading news...</div>;
  }

  if (error) {
    return <div>Error loading news: {error}</div>;
  }

  return (
    <div className="news-container heading-section mt-4">
      <h2 className="mb-3 fs-1">Latest News & <span>Events</span> </h2>
      <div className="news-wrapper">
        <div className="news-content">
          {newsItems.map((news, index) => (
            news.link ? (
              <a key={index} href={news.link} className="news-item">
                {news.text}
              </a>
            ) : (
              <div key={index} className="news-item">
                {news.text}
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
}

export default LatestNews;
