
const AlumniAssociation = () => {
    const composition = [
        { sno: 1, name: "Dr. S. Ramprabhu, Principal, Kaamadhenu College of Education", designation: "Chairperson" },
        { sno: 2, name: "Ms. S. Anbumalar", designation: "President" },
        { sno: 3, name: "Ms. S. Pradeepa", designation: "Vice President" },
        { sno: 4, name: "Mr. M. Madhan", designation: "Secretary" },
        { sno: 5, name: "Ms. Z. Heeraf Begam", designation: "Joint Secretary" },
        { sno: 6, name: "Ms. G. Gothawari", designation: "Treasurer" },
        { sno: 7, name: "Ms. Parameswari", designation: "EC Member" },
        { sno: 8, name: "Ms. Mariakeran", designation: "EC Member" },
        { sno: 9, name: "Mr. Subramani", designation: "EC Member" },
        { sno: 10, name: "Ms. K. Kamalaveni, Asst Prof, Kaamadhenu College of Education", designation: "Coordinator" },
        { sno: 11, name: "Ms. S.L. Mahendrabharathi, Asst Prof, Kaamadhenu College of Education", designation: "Mentorship Programme Coordinator" },
        { sno: 12, name: "Mr. R. Rajkumar, Asst Prof, Kaamadhenu College of Education", designation: "Professional Development Coordinator" }
    ];

    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb">
            <div className="container">
               

                <div className="row mt-3">
                    <div className="col-md-12 order-md-first">
                        <div className="text px-4">
                        <h2 className="mb-4 text-success text-center">
                                <span className="text-warning">Composition of the </span>KCE Alumni Association
                            </h2>

                        <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Designation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {composition.map((member, index) => (
                                        <tr key={index}>
                                            <td>{member.sno}</td>
                                            <td>{member.name}</td>
                                            <td>{member.designation}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
       
        </section>
    );
};

export default AlumniAssociation;
