// WhatsAppIcon.js
import React from 'react';
import '../assets/css/WhatsAppIcon.css'; // Import the CSS file for styling

const WhatsAppIcon = ({ phoneNumber }) => {
    const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    return (
        <a
            href={`https://api.whatsapp.com/send?phone=${formattedPhoneNumber}`}
            className="whatsapp-icon"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Chat on WhatsApp"
        >
            <i className="fab fa-whatsapp"></i> {/* FontAwesome WhatsApp icon */}
        </a>
    );
};

export default WhatsAppIcon;
