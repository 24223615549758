const PrincipalDesk = () => {
  
  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb"> 
      <div className="container">

      <div className="row justify-content-center mt-3 pb-4">
          <div className="col-md-12  heading-section ftco-animate text px-4">
            <h2 className="mb-4 text-success text-center">
              <span className="text-warning">Principal's </span> Desk
            </h2>   
          </div>
        </div>
        <div className="row mt-3">
        <div className=" col-md-12 order-md-first wrap-about">
            <div className="text px-4">
               <p>A warm welcome to Kaamadhenu College of Education! As the Principal, I am pleased to invite you to explore an institution dedicated to shaping the future of education. Here at Kaamadhenu, we are committed to providing an exceptional educational experience that nurtures both academic excellence and personal growth.
              </p>
              <p>
              Our advanced facilities, innovative curriculum-based activities, and passionate faculty are all designed to support your journey towards becoming a transformative teacher. We believe in fostering a learning environment where creativity, critical thinking, and compassion thrive. Our mission goes beyond teaching; we aim to inspire you to lead and make a lasting impact in the field of education.

                </p>
              <p>
              Join us at Kaamadhenu College of Education and embark on an exciting journey of discovery, growth, and transformation. Together, we can build a brighter future for education and empower the next generation of learners.

              </p>
              <p>Warm Regards,</p>
               <h5 className='font-weight-bold text-left'>Dr. S. Ramprabhu. <br/>
               <span className="qualification">M.Sc. (Chem),M.Ed.,M.Sc.,(Psy),M.A.,(Socio),UGC-NET(Edu),Ph.D.(Edu) </span> <br /> Principal</h5>
             </div>
          </div>
        </div>  
      </div>  
      <hr className="custom-hr"/>
    </section>
  );
};

export default PrincipalDesk;
