
const PlacementCell = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb">
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Placement</span> Cell
                        </h2>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                        
                            <p>
                            The Placement Cell at Kaamadhenu College of Education connects our student teachers with leading schools to help them start successful teaching careers. Each year, top private State Board, CBSE, and International schools actively recruit our student teachers through campus drives, which highlights the high standard of education and training we provide. The college provides training to enhance teaching skills and personal development, including sessions with placement trainers and spoken English courses, to ensure that student teachers are well-prepared for their careers.
                            </p>
                            
                            <h3 className="mb-4 text-primary"> Our Top Recruiters:</h3>
                            <ol style={{ listStyleType: 'disc' }}>
                            <li>Little Flower MHSS</li>
                            <li>SRN School</li>
                            <li>SNR Vidya Nethra MHSS</li>
                            <li>The Rank International Sr. School</li>
                            <li>Saru MHSS</li>
                            <li>Soundram Vidyalaya MHSS</li>
                            <li>Kumudha MHSS</li>
                            <li>Viveham MHSS</li>
                            <li>Viveham Public School</li>
                            <li>SRC MHSS</li>
                            <li>Sri Hayakgrevar Vidyalaya School</li>
                            <li>Kongu Public School</li>
                            </ol>
                         
                         
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />
                </div>
                </section>
    );
};

export default PlacementCell;
