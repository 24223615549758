
import ImageSlider from './ImageSlider'; 


import LangLab from '../assets/images/facilities/LanguageLab.jpg';

const images = [
    { src: LangLab, altText: 'LanguageLab' },
   
];


const LanguageLab = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Language</span> Lab
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            The Language Lab at Kaamadhenu College of Education enhances student teachers’ language skills through practical exercises and interactive learning. With modern technology and resources, the lab supports activities in reading, writing, speaking, and listening. Students practice pronunciation, conversation, and comprehension using advanced tools and software, which helps them build strong language abilities for academic and professional success.
                            </p>
                         </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default LanguageLab;
