

const CollegePolicy = () => {
  const plans = [
    {
      title: "",
      content: [
          "Admission Policy: The college guides students through a transparent admissions process, requiring them to submit applications by the deadline. For example, the college conducts an open house to explain admission criteria and deadlines.",
          "Attendance Policy: Faculty track attendance, and students must report any absences. For instance, students are required to submit a leave application for any missed classes due to medical reasons.",
           "Examination Policy: The college sets fair guidelines for exams, which faculty follow, and students adhere to. An example is the use of standardized question papers and grading rubrics to ensure fairness in assessments.",
            "Grievance Redressal Policy: The college provides a process for resolving issues, with faculty assisting in resolving problems and students following the procedure. For example, students can submit a formal complaint to a grievance committee for issues related to grades or faculty conduct.",
            "Disciplinary Policy: The college enforces behaviour standards and expects students to follow conduct rules. An example includes disciplinary actions for students who violate the code of conduct, such as attending mandatory counselling sessions.",
           "Research Policy: The college supports research with clear guidelines and requires adherence to ethical practices. For example, the college provides resources and guidelines for ethical research practices and requires researchers to obtain ethical clearance before starting their projects.",
           "Faculty Recruitment and Promotion Policy: The college ensures fair hiring and promotion practices, benefiting students through quality instruction. An example is a transparent recruitment process for new faculty members, including interviews and demonstrations of teaching skills.",
           "Professional Development Policy: The college encourages faculty to continue their education, enhancing student learning. For instance, the college offers financial support for faculty to attend workshops and conferences related to their field.",
           "Infrastructure and Facilities Management Policy: The college manages and maintains campus resources effectively. An example includes regular maintenance schedules for laboratory equipment and updating classroom technology to ensure optimal learning conditions.",
           "Community Engagement Policy: The college promotes faculty leadership and student involvement in community service. For example, faculty lead community outreach programs, and students participate in local service projects such as tutoring underprivileged children.",
           "Health and Safety Policy: The college implements safety measures and wellness programs to ensure well-being. An example is regular health check-ups for students and faculty, along with emergency evacuation drills.",
           "Technology Usage Policy: The college regulates the responsible use of digital resources by faculty and students. An example includes guidelines for the ethical use of online research materials and maintaining cybersecurity protocols for student data.",
           "Library Usage Policy: The college sets guidelines for library usage to ensure fair access and proper conduct. For instance, students must return borrowed materials on time and adhere to quiet study zones.",
          "Laboratory Usage Policy: The college establishes protocols for laboratory use to ensure safety and proper functioning. An example is requiring students to follow safety guidelines and clean up after experiments.",
          "Transportation Policy: The college provides guidelines for using transportation services. For example, students must adhere to bus schedules and maintain proper behaviour while using college transport.",
          "Uniform Policy: The college enforces a uniform policy to maintain a professional appearance. For example, students are required to wear the designated college uniform during academic hours and official events.",
          "Cleanliness Policy: The college maintains a clean and hygienic campus with regular cleaning and effective waste management. We encourage everyone to keep their spaces tidy and promptly report any issues. Accessible sanitation stations support good hygiene practices, and our inspections and training ensure high cleanliness standards throughout the college.",
      ]
    }
  
  ];

  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center mt-3">
          <div className="col-md-12 heading-section ftco-animate text px-4">
            <h2 className="mb-4 text-success text-center">
            College Policy <span className="text-warning">Framework</span>
            </h2>
            <p>
            Kaamadhenu College of Education follows a clear set of policies to support its educational mission:
   </p>
          </div>
        </div>
        {plans.map((plan, index) => (
          <div key={index} className="row">
            <div className="col-md-12 order-md-first">
              <div className="text px-4 py-4 ftco-animate">
                <ul>
                  {plan.content.map((item, idx) => (
                    <li key={idx} className="mb-2">
                      <strong>{item.split(":")[0]}:</strong>
                      {item.split(":")[1]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CollegePolicy;
