
const SocialMedia = () => {
    
    return (
        <div className="text-center">
        <ul className="ftco-footer-social list-unstyled mt-3">
        <li className="ftco-animate">
          <a href="https://www.youtube.com/@kaamadhenucollegeofeducati1270" target="blank"><span className="fab fa-youtube"></span></a>
        </li>
        <li className="ftco-animate">
          <a href="https://www.facebook.com/p/Kaamadhenu-College-of-Education-100088155169880/" target='blank'><span className="fab fa-facebook-f"></span></a>
        </li>
        <li className="ftco-animate">
           <a href="https://www.instagram.com/kaamadhenu_institutions/?hl=en"  target='blank'><span className="fab fa-instagram"></span></a>
        </li>
      </ul>
    </div>
    )
    

}
export default SocialMedia