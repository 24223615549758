

import ImageSlider from './ImageSlider'; 


import LibraryImage from '../assets/images/facilities/Library.jpg';

const images = [
    { src: LibraryImage, altText: 'Cafeteria' },
   
];
const Library = () => {
  return (

<section className="ftco-section ftco-no-pb">
    <div className="container">
    
    <div className="row justify-content-center  pb-4">
        <div className="col-md-12  heading-section ftco-animate text px-4">
          <h2 className="mb-4 text-success text-center">
            <span className="text-warning"> Library </span> & <span className="text-success"> Resources </span>
          </h2>   
        </div>
      </div>
        
    
      <div className="row mt-3">
        <div className=" col-md-8 order-md-first wrap-about">
          <div className="text px-4">
             
              <p> 
                The library at Kaamadhenu College of Education supports the academic and research needs of student teachers with a wide range of resources. It offers over 6,200 books across various educational disciplines and other knowledge areas. The collection includes more than 5 national and 2 international journals that provide current research and academic articles. Students also have access to numerous e-books, online databases, and educational software. The library features an extensive array of reference materials, including encyclopedias, dictionaries, and atlases. It also provides comprehensive books and study materials for competitive exams such as CTET, TNTET, UGC-NET, TNSET, PGTRB, TNPSC, and SSC. Modern facilities like study areas, reading rooms, and digital catalogues enhance the learning experience, creating an environment that fosters academic excellence and intellectual growth.
              </p>
            
            </div>
          </div>
          <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
      
      </div>  
      </div>

        </section>
  );
};

export default Library;
