import AlumniAssociationTable from "./AlumniAssociationTable"
import StudentCouncilImage from '../assets/images/studentCouncil.jpg'; // Adjust the path as needed

const AlumniAssociation = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb">
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">KCE Alumni</span> Association
                        </h2>
                    </div>
                </div>
              
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            The KCE Alumni Association at Kaamadhenu College of Education connects former students, fostering a strong network of professionals and supporters. The association plays a vital role in maintaining lasting relationships between alumni and the college, providing opportunities for collaboration, mentorship, and professional development.
                            </p>
                            <h3 className="mb-4 text-primary">Key Activities: </h3>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Networking Events: Organizes reunions, meet-ups, and networking events to strengthen connections among alumni and current students.
                                </li>
                                <li className="list-group-item">Mentorship Programs: Facilitates mentorship opportunities where experienced alumni guide and support current students in their academic and career pursuits.
                                </li>
                                <li className="list-group-item">Professional Development: Hosts workshops, seminars, and talks to help alumni stay updated with industry trends and continue their professional growth.
                                </li>
                                <li className="list-group-item">Community Engagement: Encourages alumni to contribute to college initiatives, support scholarships, and participate in community service projects.

                                </li>
                            </ul>
                            <h3 className="mb-4 text-primary"> Objectives of the KCE Alumni Association</h3>
                            <ol style={{ listStyleType: 'disc' }}>
                                <li>To foster alumni connections.</li>
                                <li>To support professional development.</li>
                                <li>To encourage alumni involvement.</li>
                                <li>To promote lifelong learning.</li>
                                <li>To strengthen college ties.</li>
                                <li>To provide support and recognition.</li>
                                <li>To develop and sustain alumni resources.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="col-md-4 order-md-last wrap-about">
                        <div>
                            <img 
                                src={StudentCouncilImage} 
                                alt="Student Council" 
                                className="img-fluid" 
                                width="100%" 
                                height="auto" 
                            />
                        </div>
                    </div>
                </div>  

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="text px-4">
                           <AlumniAssociationTable/>
                        </div>
                    </div>
                </div>

                
                <hr className="custom-hr" />
                </div>
                </section>
    );
};

export default AlumniAssociation;
