import LatestNews from './LatestNews';

const VisionMission = () => {
  
  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb "> 
      <div className="col-md-12 row">
        
      <div className="row justify-content-center mt-3 pb-4">
          <div className="col-md-8 text-center heading-section ftco-animate">
            <h2 className="mb-4 text-success">
              <span className="text-warning">Vision &amp;  </span> Mission
            </h2>
          </div>
        </div>

      <div className="col-md-8">

      <div className="container">


        <div className="row">
          <div className=" col-md-12 order-md-first">
            <div className="text px-4">
              <h2 className="mb-4 text-primary"> Vision </h2>
              <p>
              To be a premier institution in teacher education, integrating value-based learning with advanced technology to cultivate compassionate, and innovative teachers who drive educational excellence.
              </p>
            </div>
          </div>
      </div>

      <div className="row">
          <div className=" col-md-12 order-md-first">
            <div className="text px-4">
              <h2 className="mb-4 text-primary"> Mission </h2>
              <p>
              To provide quality teacher education that fuses traditional values with modern practices, empowering future teachers to lead, inspire, and contribute to societal growth with integrity and distinction.
              </p>
          
            </div>
          </div>
        </div>  
      
        
        </div>

      </div>
        <div className="col-md-4">
            <LatestNews />
          </div>
      </div>
      <hr className="custom-hr"/>
    </section>

  );
};

export default VisionMission;
