
const Admission = () => {
    const subjects = [
        { sno: 1, subject: "Tamil", eligibility: "U.G." },
        { sno: 2, subject: "English", eligibility: "U.G." },
        { sno: 3, subject: "Mathematics", eligibility: "U.G." },
        { sno: 4, subject: "Physical Science (Physics)", eligibility: "U.G." },
        { sno: 5, subject: "Physical Science (Chemistry)", eligibility: "U.G." },
        { sno: 6, subject: "Biological Science (Botany)", eligibility: "U.G." },
        { sno: 7, subject: "Biological Science (Zoology)", eligibility: "U.G." },
        { sno: 8, subject: "History", eligibility: "U.G." },
        { sno: 9, subject: "Geography", eligibility: "U.G." },
        { sno: 10, subject: "Computer Science", eligibility: "U.G." },
        { sno: 11, subject: "Economics", eligibility: "P.G." },
        { sno: 12, subject: "Commerce", eligibility: "P.G." },
        { sno: 13, subject: "B.E.", eligibility: "U.G." },
        { sno: 14, subject: "B.Tech.", eligibility: "U.G." }
    ];

    const communityRequirements = [
        { community: "OC", marks: "50%" },
        { community: "BC/BCM", marks: "45%" },
        { community: "MBC/DNC", marks: "43%" },
        { community: "SC/SCA/ST", marks: "40%" }
    ];

    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb">
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">B.Ed.</span> Admission
                        </h2>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12 order-md-first">
                        <div className="text px-4">
                            <h2 className="mb-4 text-primary">
                                Major Subjects Offered Under B.Ed. Degree Programme
                            </h2>

                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Major Subjects</th>
                                        <th>Eligibility</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subjects.map((subject, index) => (
                                        <tr key={index}>
                                            <td>{subject.sno}</td>
                                            <td>{subject.subject}</td>
                                            <td>{subject.eligibility}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <h3 className="text-primary mt-4">Eligibility Criteria for Admission</h3>
                            <p>For subjects listed as S.No. 1-10, which are taught at the high school level, the required qualification for admission to the B.Ed. degree program is a U.G. degree in the relevant subject, with a minimum percentage of marks as specified in the following table.</p>
                            
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Community</th>
                                        <th>% of Marks in U.G.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {communityRequirements.map((requirement, index) => (
                                        <tr key={index}>
                                            <td>{requirement.community}</td>
                                            <td>{requirement.marks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <ul>
                                <li>Marks obtained by the candidates in the U.G. degree course in Part-III & IV alone shall be considered to arrive at the percentage of marks mentioned above.</li>
                                <li>The candidates who are qualified in U.G. degree under Open University System after passing 10th Std. and +2 examinations shall alone be considered for admission to B.Ed. degree programme.</li>
                                <li>The candidates who are qualified in U.G. degree under Open University System without passing 10th Std. and +2 examinations and subsequently passing 10th Std. and +2 examinations are not eligible for admission to B.Ed. degree programme.</li>
                                <li>For Differently abled (Physically and Visually challenged) candidates, a minimum pass in the requisite qualification is enough.</li>
                                <li>All B.E./B.Tech. graduates, regardless of their engineering or technology branch, are eligible to pursue a B.Ed. degree with a focus on Mathematics or Physical Science as their pedagogy subject. Additionally, graduates with a B.E./B.Tech. in Computer Science-related branches are eligible to study Computer Science, and those from Biotechnology-related branches are eligible to study Biological Science as their pedagogy subject.</li>
                                <li>For candidates with a P.G. degree in Commerce or Economics (S.No. 11 & 12), the required qualification for admission to the B.Ed. degree programme is a relevant P.G. degree with at least 50% marks. Additionally, the major subject in both the U.G. and P.G. degrees must be the same.</li>
                                <li>For the candidates who have done their bachelor’s degree in engineering / technology/, the marks obtained in their degree shall be considered.</li>
                            </ul>
 </div>
                    </div>
                </div>
        </div>
        <hr className="custom-hr"/>
        </section>
    );
};

export default Admission;
