

import ImageSlider from './ImageSlider'; 


import SeminarHallImage from '../assets/images/facilities/SeminarHall.jpg';

const images = [
    { src: SeminarHallImage, altText: 'Seminar Hall' },
   
];
const SeminarHall = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Seminar</span> Hall
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            The Seminar Hall at Kaamadhenu College of Education serves as a versatile venue for academic events, workshops, seminars, and conferences. With a seating capacity of 250, it accommodates large groups comfortably. The hall features state-of-the-art audio-visual technology, including high-definition projectors and sound systems, which facilitates seamless presentations and interactive sessions. Its spacious seating arrangement and comfortable environment support a wide range of activities, from guest lectures to student presentations. The modern amenities and thoughtful design ensure that every event is conducted efficiently and professionally, enhancing the overall academic experience for students and faculty alike.
                            </p>
                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default SeminarHall
;
