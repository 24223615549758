import { Link } from 'react-router-dom';
import SocialMedia from './SocialMedia';
const Footer = () => {
  return (
    <footer className="ftco-footer ftco-bg-dark ftco-section mt-5">
      <div className="container">
        <div className="row mb-2">
          <div className="col-md-6 col-lg-4">
            <div className="ftco-footer-widget">
              <h2 className="ftco-heading-2">Have a Questions?</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <span className="fa fa-map-marker pr-3"></span>
                    <span className="text">
                    Kaamadhenu Nagar, D.G. Pudur Post, Sathyamangalam - 638503, Erode DT , Tamil Nadu 
                    </span>
                  </li>
                  <li>
                    <a href="tel:04295223943">
                      <span className="fa fa-phone pr-3"></span>
                      <span className="text">04295 223943</span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:kascbed@gmail.com">
                      <span className="fa fa-envelope pr-3"></span>
                      <span className="text">kascbed@gmail.com</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

    
<div className="col-md-6 col-lg-4">
  <div className="ftco-footer-widget ml-md-4">
    <h2 className="ftco-heading-2 text-center">Links</h2>
    <div className="row text-center" >
      <div className="col-6">
        <ul className="list-unstyled">
          <li><Link to="/home"><span className="fa fa-chevron-right mr-2"></span>Home</Link></li>
          <li><Link to="/aboutSection"><span className="fa fa-chevron-right mr-2"></span>About</Link></li>
          <li><Link to="/course"><span className="fa fa-chevron-right mr-2"></span>Course</Link></li>
        </ul>
      </div>
      <div className="col-6">
        <ul className="list-unstyled">
          <li><Link to="/admission"><span className="fa fa-chevron-right mr-2"></span>Admission</Link></li>
          <li><Link to="/gallery"><span className="fa fa-chevron-right mr-2"></span>Gallery</Link></li>
          <li><Link to="/contact"><span className="fa fa-chevron-right mr-2"></span>Contact</Link></li>
        </ul>
      </div>
    </div>
  </div>
</div>


          <div className="col-md-6 col-lg-4">
            <div className="ftco-footer-widget">
              <h2 className="ftco-heading-2 mb-0 text-center">Connect With Us</h2>
              <SocialMedia/>
            </div>
          </div>
          
      
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="copyright">
              Copyright &copy;2024  - All rights reserved | Developed by <a href="https://www.neinus.com" target="_blank" rel="noopener noreferrer">Neinus Technologies</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
