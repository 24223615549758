import React, { useState, useEffect } from 'react';
import bg1 from '../assets/images/Home_bg_01.jpg';
import bg2 from '../assets/images/Home_bg_02.jpg';
import bg3 from '../assets/images/Home_bg_03.jpg';
import bg4 from '../assets/images/Home_bg_04.jpg';
import bg5 from '../assets/images/Home_bg_05.jpg';
import '../assets/css/imageCarousel.css'; // Custom styles

const images = [bg1, bg2, bg3, bg4, bg5];

const texts = [
  "Welcome to Kaamadhenu College of Education!",
  "Initiate Your Journey, Innovate Your Future",
  "Empowering Future Teachers with Excellence in Education!",
  "Choose Only the Best for Your Transformation!",
  "Lead with Confidence - Our B.Ed. Programme Prepares You for Success",
];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="carousel">
      <button
        className="carousel-button prev"
        onClick={prevSlide}
        aria-label="Previous slide"
      >
        ❮
      </button>
      <div className="carousel-container">
        <div
          className="carousel-slides"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="carousel-slide"
              style={{ backgroundImage: `url(${image})` }}
            >
              <div className="overlay"></div>
              <div className="text-container">
                <h2 className={`typewriter ${currentIndex === index ? 'animate' : ''}`}>
                  {texts[index]}
                </h2>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        className="carousel-button next"
        onClick={nextSlide}
        aria-label="Next slide"
      >
        ❯
      </button>
    </div>
  );
};

export default ImageCarousel;
