

import ImageSlider from './ImageSlider'; 


import CafeteriaImage from '../assets/images/facilities/MathsLab.jpg';

const images = [
    { src: CafeteriaImage, altText: 'Cafeteria' },
   
];
const Cafeteria = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Cafeteria</span> 
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            The cafeteria at Kaamadhenu College of Education provides a vibrant space for students and staff to enjoy foods throughout the day. It offers a diverse menu featuring nutritious and delicious options. The cafeteria ensures a pleasant dining experience with comfortable seating and a clean environment. Regular feedback is collected to improve food quality and service.
                            </p>
                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default Cafeteria;
