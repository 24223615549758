
const ValueAddedProgrammes = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Value Added </span> Programmes
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-12 order-md-first">
                        <div className="text px-4">
                            <p>
                            Value-added programmes in Kaamadhenu College of Education are designed to enhance the educational experience by providing students with additional skills, knowledge, and competencies beyond the standard curriculum. These programmes aim to develop a well-rounded skill set that prepares students for diverse challenges in their professional and personal lives.     </p>
                         
                            <h3 className="text-primary">List of Value-Added Courses </h3>
                            <ol>
                                <li>AI Tools in Teaching Learning</li>
                                <li>Interactive Multimedia in Education</li>
                                <li>Data Management and Analysis for Teachers</li>
                                <li>Cybersecurity and Online Safety for Teachers</li>
                                <li>Personality Development, Advanced Public Speaking and Presentation</li>
                                <li>Holistic Wellness and Yoga</li>
                                <li>Essential First Aid and Life Support</li>
                                <li>Comprehensive Health and Wellness</li>
                                <li>Professional English Communication Skills</li>
                                <li>Mindfulness and Stress Management</li>
                            </ol>

                        </div>
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default ValueAddedProgrammes;
