

import ImageSlider from './ImageSlider'; 


import SocialImage from '../assets/images/facilities/supw.jpg';

const images = [
    { src: SocialImage, altText: 'Maths Lab' },
   
];
const Supw = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Socially Useful Productive Work 
                            </span> (SUPW)
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            Socially Useful Productive Work (SUPW) stands as a key element of the curriculum at Kaamadhenu College of Education, merging academic knowledge with practical, community-focused activities. Student teachers actively engage in diverse projects such as candle making, soap making, soap powder production, agarbathis making, book binding, and creating wealth from waste. These hands-on activities foster practical skills, creativity, and a strong sense of social responsibility. SUPW enables student teachers to apply their skills meaningfully, preparing them to make positive contributions to their communities and beyond.
                            </p>
                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default Supw;
