
import ImageSlider from './ImageSlider'; 


import MathsLab from '../assets/images/facilities/MathsLab.jpg';

const images = [
    { src: MathsLab, altText: 'Maths Lab' },
   
];
const MathLab = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Math </span> Lab
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            The Mathematics Lab at Kaamadhenu College of Education provides an engaging environment for student teachers to explore and understand mathematical concepts. Equipped with a variety of tools and resources, including models, geometric shapes, measurement instruments, and software applications, the lab allows student teachers to interact with mathematical theories through hands-on activities and experiments. 
                            </p>
                            <p>
                            The lab enhances problem-solving skills, critical thinking, and mathematical reasoning. By offering practical experiences, it helps students grasp abstract concepts and appreciate the real-world applications of mathematics. This interactive approach deepens their understanding and prepares them to teach and apply mathematics effectively in their future careers.
                            </p>
                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default MathLab;
