import IQACTable from "./IqacTable";

const Iqac = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb">
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Internal Quality Assurance Cell</span> (IQAC)
                        </h2>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12 ">
                        <div className="text px-4">
                        
                            <p>
                            The Internal Quality Assurance Cell (IQAC) at Kaamadhenu College of Education is dedicated to fostering a culture of continuous improvement and academic excellence. Established as a pivotal body, the IQAC ensures that the college maintains and enhances its quality standards in both academic and administrative domains.
                            </p>
                            
                            <h3 className="mb-4 text-primary"> Objectives:</h3>
                            <ol style={{ listStyleType: 'disc' }}>
                            <li>To develop and implement strategies for consistent academic and administrative improvements.</li>
                            <li>To actively involve students, faculty, and other stakeholders in the quality assurance process.</li>
                            <li>To promote and institutionalize best practices across all functions of the college.</li>
                            <li>To establish a robust feedback system to gather insights and drive improvements.</li>
                            <li>To lead the preparation and submission of the Annual Quality Assurance Report (AQAR) in accordance with NAAC guidelines.</li>

                            </ol>
                         
                         <IQACTable/>
                        </div>
                    </div>
                </div>
                <hr className="custom-hr" />
                </div>
                </section>
    );
};

export default Iqac;
