import { FaFilePdf } from 'react-icons/fa'; // Import the FontAwesome PDF icon


const AdministrativeSection = () => {
  return (

<section className="ftco-section ftco-no-pb">
    <div className="container">
    
    <div className="row justify-content-center  pb-4">
        <div className="col-md-12  heading-section ftco-animate text px-4">
          <h2 className="mb-4 text-success text-center">
            <span className="text-warning">Administrative </span> Staff
          </h2>   
        </div>
      </div>
        
    
      <div className="row mt-3">
        <div className=" col-md-12 order-md-first wrap-about">
          <div className="text px-4">
             
          <p>
                            <strong>Administrative Staff :- TBD </strong>
                            <a href="#" download className="text-primary mt-3">
                            <FaFilePdf className="pdf-icon" /> {/* PDF icon in red */}
                            
                            </a>
                         </p>
             
            </div>
        </div>
    
      
      </div>  
      </div>
      {/* <hr className="custom-hr"/> */}
        </section>
  );
};

export default AdministrativeSection;
