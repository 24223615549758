import React from 'react';
import { Outlet } from 'react-router-dom';


const Home = () => {
  return (
    <div>
    
      <div>
        <div className="row">
            <Outlet /> {/* Render nested route components */}
          </div>
      </div>
    
    </div>
  );
};

export default Home;
