import { FaFilePdf } from 'react-icons/fa'; // Import the FontAwesome PDF icon

const Aqar = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            AQAR
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-12 order-md-first">
                        <div className="text px-4">
                        
                         <p>
                            <strong>2023-2024: </strong>
                            <a href="#" download className="text-primary mt-3">
                            <FaFilePdf className="pdf-icon" /> {/* PDF icon in red */}
                            
                            </a>
                            </p>
                            <p>
                            <strong>2024-2025: </strong>
                            <a href="#" download className="text-primary mt-3">
                            <FaFilePdf className="pdf-icon" /> {/* PDF icon in red */}
                            
                            </a>
                            </p>
                            <p>
                            <strong> 2025-2026: </strong>
                            <a href="#" download className="text-primary mt-3">
                            <FaFilePdf className="pdf-icon" />{/* PDF icon in red */}
                            
                            </a>
                          </p>
                           
                        </div>
                    </div>

                   
                </div>  
            </div>
         
        </section>
    );
};

export default Aqar;
