import ImageSlider from './ImageSlider'; 


import PsychologyLab1 from '../assets/images/facilities/PsychologyLab-1.jpg';
import PsychologyLab2 from '../assets/images/facilities/PsychologyLab-2.jpg';


const images = [
    { src: PsychologyLab1, altText: 'Psychology Lab-1' },
    { src: PsychologyLab2, altText: 'Psychology Lab-2' },
  
];

const PsychologyLab = () => {
   
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Psychology </span> Lab
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                                The ‘Wilhelm Wundt Psychology Lab’ at Kaamadhenu College of Education is an essential resource for student teachers, equipping them with the practical skills and knowledge needed to address the diverse psychological needs of their future students. The lab serves as a crucial hub for exploring the complexities of human behaviour and mental processes, offering a comprehensive array of psychological testing tools, assessment materials, and observational instruments.
                            </p>
                            <p>
                                Equipped with nearly 60 psychological tests and 15 specialized equipments, the lab enables student teachers to engage deeply with concepts such as cognitive development, learning processes, personality assessment, and emotional regulation. Student teachers gain valuable insights by conducting experiments, administering tests, and analyzing data, which helps them understand these critical areas of psychology.
                            </p>
                            <p><strong>Key apparatus available in the lab include:</strong></p>
                            <ul>
                                <li>Müller-Lyer Illusion Apparatus</li>
                                <li>Tachistoscope</li>
                                <li>Finger Dexterity Board</li>
                                <li>Raven's Progressive Matrices</li>
                                <li>Mirror Drawing Apparatus</li>
                                <li>Human Maze with Error Counter</li>
                                <li>Steadiness Tester</li>
                                <li>Memory Drum</li>
                                <li>Minnesota Multiphasic Personality Inventory®-2 (MMPI-2)</li>
                                <li>Dyslexia Screening Test-Junior India Kit (DST-J India)</li>
                                <li>Wechsler Memory Scale – 3rd Edition, India (WMS-III INDIA)</li>
                                <li>Wechsler Adult Intelligence Scale-Fourth Edition (WAIS IV) India</li>
                                <li>Beck Depression Inventory II (BDI-II)</li>
                                <li>Eysenck Personality Inventory</li>
                                <li>Various Paper-Pencil Tests and Standard Questionnaires</li>
                            </ul>
                        </div>
                    </div>

                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                     
    
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default PsychologyLab;
