
const JoinSecretarySection = ({hideElem}) => {
   return (
    <section className="ftco-section ftco-no-pt ftco-no-pb"> 
      <div className="container">
        {!hideElem && (
          <div className="row justify-content-center mt-3 pb-4">
            <div className="col-md-12  heading-section ftco-animate text px-4">
              <h2 className="mb-4 text-success text-center">
                <span className="text-warning">Management </span> Team
              </h2>
            </div>
          </div>
        )}

        <div className="row mt-3">
        <div className=" col-md-12 order-md-first wrap-about">
            <div className="text px-4">
              <h2 className="mb-4 text-primary">Joint Secretary’s Message</h2>
              <p>
              Welcome to Kaamadhenu College of Education! As the Joint Secretary, I am excited to welcome you to our esteemed institution. Here, we are dedicated to cultivating an environment where innovation meets excellence, and where every student has the opportunity to thrive. Our cutting-edge resources, experienced faculty, and supportive community are all geared towards fostering your growth as an educator. Embrace the opportunity to join us and start a transformative journey where your potential is realized and your future as a dignified teacher. We look forward to guiding you towards achieving your aspirations and making a meaningful impact in education.

              </p>
              <h5 className='font-weight-bold text-right'>Dr. P. Malar Selvi. <span className="qualification">M.A.,M.Ed.,M.A.,M.Phil.,Ph.D.</span>
              <br/>Joint Secretary</h5>
             </div>
          </div>
        </div>  
      </div>  
      <hr className="custom-hr"/>
    </section>
  );
};

export default JoinSecretarySection;
