
const ChairmanSection = () => {
  
  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb"> 
      <div className="container">

      <div className="row justify-content-center mt-3 pb-4">
          <div className="col-md-12  heading-section ftco-animate text px-4">
            <h2 className="mb-4 text-success text-center">
              <span className="text-warning">Management </span> Team
            </h2>   
          </div>
        </div>
          
        <div className="row mt-3">
          <div className=" col-md-12 order-md-first wrap-about">
            <div className="text px-4">
              <h2 className="mb-4 text-primary"> Chairman’s Message </h2>
              <p>
              Welcome to Kaamadhenu College of Education, a true temple of learning. I am honored to lead an institution dedicated to fostering excellence in teacher education. Our mission is to develop well-rounded educators who are prepared to make a significant impact in the field of education. We believe that teachers play a vital role in shaping the future, and our goal is to equip them with the skills, knowledge, and values needed to drive positive change in society. We invite you to explore our programs, facilities, and the vibrant community we have built. Together, let us embark on a journey to inspire, innovate, and transform education. Here, you will find a supportive environment designed to equip you with the skills and knowledge essential for a successful teaching career. We look forward to guiding you on your educational journey and helping you make a meaningful impact in the field of education. Join us in our mission to inspire and transform the future.
              </p>
              <h5 className='font-weight-bold text-right'>Shri. R. Perumalsamy <br/>Chairman</h5>
             </div>
          </div>
        </div>  
      </div>
      <hr className="custom-hr"/>
    </section>
  );
};

export default ChairmanSection;
