
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import SocialMedia from './SocialMedia';

const HeaderTop = () => {
  return (
    <div className=" bg-primary header-top-hidden">
      <div className="container">
        <div className="row no-gutters d-flex align-items-start align-items-center px-md-0">
          <div className="col-lg-12 d-block">
            <div className="row d-flex">
              <div className="col-md-6 pl-4 pr-4 d-flex topper align-items-center">
                <div className="icon bg-fifth mr-2 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </div>
                <span  className='text-white'>
                  Kaamadhenu Nagar, D.G. Pudur Post, Sathyamangalam - 638503, Erode DT , Tamil Nadu 
                </span>
              </div>
              <div className="col-md pr-4 d-flex topper align-items-center">
                <div className="icon bg-secondary mr-2 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <span className='text-white'>kascbed@gmail.com</span>
              </div>
              <div className="col-md pr-4 d-flex topper align-items-center">
                <div className="icon bg-tertiary mr-2 d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                </div > 
                <span  className='text-white'>04295 223943</span>

              </div>
              <div className="col-md pr-4 d-flex topper align-items-center">
                <SocialMedia/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
