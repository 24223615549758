import collegeImage from '../assets/images/college.png'; // Adjust the path as needed

const AboutSection = () => {
  
  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb"> 
      <div className="container">
      <div className="row justify-content-center mt-3 pb-4">
          <div className="col-md-8 text-center heading-section ftco-animate">
            <h2 className="mb-4 text-success">
              <span className="text-warning"> Kaamadhenu College  </span> of Education
            </h2>
          </div>
        </div>

        <div className="row mt-3">
          <div className=" col-md-8 order-md-first wrap-about">
            <div className="text px-4">
              <h2 className="mb-4 text-primary"> About the College </h2>
              <p>
              Kaamadhenu College of Education is a leading institution committed to excellence in teacher education, providing a nurturing environment where future teachers are equipped with the skills, knowledge, and values essential for a successful teaching career. The college was established in 2015 by Shri R. Perumalsamy, a distinguished philanthropist and agriculturist deeply committed in advancing education for society. It is affiliated with Tamil Nadu Teachers Education University (TNTEU) and recognized by the National Council for Teacher Education (NCTE). The Chairman believes in cultivating well integrated teachers who can drive social transformation, recognizing that teachers have the power to influence and shape society. Our two-year B.Ed. programme is designed to foster critical thinking, innovation, and effective pedagogical practices. Located on a serene, eco-friendly campus, the college boasts modern facilities, including advanced laboratories, well equipped library, and technology enhanced classrooms that create a dynamic learning experience. We prioritize holistic student support, offering separate hostels, transport services, and comprehensive safety measures, ensuring a secure and conducive environment for learning. Our vibrant campus life, enriched by various active clubs and committees, promotes leadership, creativity, and community engagement. Led by a visionary management team, Kaamadhenu College of Education is dedicated to shaping not just teachers, but leaders who will inspire and transform the future of education.  </p>
            </div>
          </div>

          <div className=" col-md-4 order-md-first wrap-about">
            <div className="mt-5 pt-5">
              <img src={collegeImage} alt="Kaamadhenu College of Education" className="img-fluid" width={400} height={200} />
       
              </div>
          </div>
      </div>    
      </div>
      <hr className="custom-hr"/>
    </section>
  );
};

export default AboutSection;
