

const BestPractices = () => {
  const plans = [
    {
      title: "",
      content: [
        "The college starts each day with a morning prayer session to build community and focus on moral and spiritual values.",
        "It supports holistic development through extracurricular activities like cultural events, sports, and wellness programs.",
        "Environmental sustainability is emphasized with waste segregation and energy conservation practices.",
        "Regular community service and outreach programs encourage positive contributions to society.",
        "Health and wellness are supported with check'ups, yoga classes, and mindfulness sessions.",
        "Professional development is promoted through workshops, seminars, and training for faculty and students.",
        "The college provides dedicated support services for academic, personal, and career needs.",
        "Technology is integrated into teaching with digital resources and interactive tools.",
        "Cultural and literary events such as debates, and art exhibitions enrich campus life.",
        "Leadership and civic responsibility are encouraged through student council activities and leadership training.",
        "The college incorporates advanced technology and digital solutions in education.",
        "Collaboration among students, faculty, and management is fostered through regular meetings and joint efforts.",
      ]
    }
  
  ];

  return (
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center mt-3">
          <div className="col-md-12 heading-section ftco-animate text px-4">
            <h2 className="mb-4 text-success text-center">
            Best <span className="text-warning">Practices</span>
            </h2>
            <p>
            Kaamadhenu College of Education follows a clear set of policies to support its educational mission:
   </p>
          </div>
        </div>
        {plans.map((plan, index) => (
          <div key={index} className="row">
            <div className="col-md-12 order-md-first">
              <div className="text px-4 py-4 ftco-animate">
                <ul>
                  {plan.content.map((item, idx) => (
                    <li key={idx} className="mb-2">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BestPractices;
