import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import '../assets/css//Navbar.css';

function Navbar() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

  const handleMouseEnter = (dropdownId) => {
    setActiveDropdown(dropdownId);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  const handleDropdownClick = (dropdownId, e) => {
    e.preventDefault();
    setActiveDropdown(activeDropdown === dropdownId ? null : dropdownId);
  };

  const handleSubmenuClick = () => {
    setIsNavbarCollapsed(true);
  };

  return (
    <div className="navbar-container">
      {/* Navigation Bar Section */}
      <nav className="navbar navbar-expand-lg bg-primary text-white w-100">
        <div className="container-fluid">
          {/* Toggle Button for Mobile View */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!isNavbarCollapsed}
            aria-label="Toggle navigation"
            onClick={() => setIsNavbarCollapsed(!isNavbarCollapsed)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* Navigation Links with Dropdown */}
          <div className={`collapse navbar-collapse ${isNavbarCollapsed ? '' : 'show'}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item active">
                <Link to="/" className="nav-link" onClick={handleSubmenuClick}>Home</Link>
              </li>
              <li
                className="nav-item dropdown"
                onMouseEnter={() => handleMouseEnter('aboutDropdown')}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  id="aboutDropdown"
                  role="button"
                  aria-expanded={activeDropdown === 'aboutDropdown'}
                  onClick={(e) => handleDropdownClick('aboutDropdown', e)}
                >
                  About Us
                </Link>
                <ul className={`dropdown-menu ${activeDropdown === 'aboutDropdown' ? 'show' : ''}`} aria-labelledby="aboutDropdown">
                  <li><Link className="dropdown-item" to="/visionMission" onClick={handleSubmenuClick}>Vision & Mission</Link></li>
                  <li><Link className="dropdown-item" to="/aboutSection" onClick={handleSubmenuClick}>About the College</Link></li>
                  <li
                    className="dropdown-submenu"
                    onMouseEnter={() => handleMouseEnter('managementDropdown')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle"
                      id="managementDropdown"
                      role="button"
                      aria-expanded={activeDropdown === 'managementDropdown'}
                      onClick={(e) => handleDropdownClick('managementDropdown', e)}
                    >
                      Management Team
                    </Link>
                    <ul className={`dropdown-menu ${activeDropdown === 'managementDropdown' ? 'show' : ''}`} aria-labelledby="managementDropdown">
                      <li><Link className="dropdown-item" to="/chairmanMessage" onClick={handleSubmenuClick}>Chairman's Message</Link></li>
                      <li><Link className="dropdown-item" to="/secretaryMessage" onClick={handleSubmenuClick}>Secretary's Message</Link></li>
                      <li><Link className="dropdown-item" to="/jointSecretaryMessage" onClick={handleSubmenuClick}>Joint Secretary's Message</Link></li>
                       </ul>

                  </li>
                  <li><Link className="dropdown-item" to="/principalDesk" onClick={handleSubmenuClick}>Principal's Desk</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="/course" className="nav-link" onClick={handleSubmenuClick}>Course</Link>
              </li>
              <li className="nav-item">
                <Link to="/admission" className="nav-link" onClick={handleSubmenuClick}>Admission</Link>
              </li>

              <li
                className="nav-item dropdown"
                onMouseEnter={() => handleMouseEnter('facultyDropdown')}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  id="facultyDropdown"
                  role="button"
                  aria-expanded={activeDropdown === 'facultyDropdown'}
                  onClick={(e) => handleDropdownClick('facultyDropdown', e)}
                >
                  Faculty & Staff
                </Link>
                <ul className={`dropdown-menu ${activeDropdown === 'facultyDropdown' ? 'show' : ''}`} aria-labelledby="facultyDropdown">
                  <li><Link className="dropdown-item" to="/faculty" onClick={handleSubmenuClick}>Faculty Members</Link></li>
                  <li><Link className="dropdown-item" to="/administration" onClick={handleSubmenuClick}>Administrative Staff</Link></li>
                </ul>
              </li>
              <li
                className="nav-item dropdown"
                onMouseEnter={() => handleMouseEnter('facilitiesDropdown')}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  id="facilitiesDropdown"
                  role="button"
                  aria-expanded={activeDropdown === 'facilitiesDropdown'}
                  onClick={(e) => handleDropdownClick('facilitiesDropdown', e)}
                >
                  Facilities
                </Link>
                <ul className={`dropdown-menu ${activeDropdown === 'facilitiesDropdown' ? 'show' : ''}`} aria-labelledby="facilitiesDropdown">
                  <li><Link className="dropdown-item" to="/library" onClick={handleSubmenuClick}>Library & Resources</Link></li>
                  <li
                    className="dropdown-submenu"
                    onMouseEnter={() => handleMouseEnter('laboratoriesDropdown')}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to="#"
                      className="dropdown-item dropdown-toggle"
                      id="laboratoriesDropdown"
                      role="button"
                      aria-expanded={activeDropdown === 'laboratoriesDropdown'}
                      onClick={(e) => handleDropdownClick('laboratoriesDropdown', e)}
                    >
                      Laboratories
                    </Link>
                    <ul className={`dropdown-menu ${activeDropdown === 'laboratoriesDropdown' ? 'show' : ''}`} aria-labelledby="laboratoriesDropdown">
                      <li><Link className="dropdown-item" to="/psychologyLab" onClick={handleSubmenuClick}>Psychology Lab</Link></li>
                      <li><Link className="dropdown-item" to="/integratedScienceLab" onClick={handleSubmenuClick}>Integrated Science Lab</Link></li>
                      <li><Link className="dropdown-item" to="/edutechLab" onClick={handleSubmenuClick}>Educational Technology Lab</Link></li>
                      <li><Link className="dropdown-item" to="/languageLab" onClick={handleSubmenuClick}>Language Lab</Link></li>
                      <li><Link className="dropdown-item" to="/computerLab" onClick={handleSubmenuClick}>Computer Science Lab</Link></li>
                      <li><Link className="dropdown-item" to="/mathLab" onClick={handleSubmenuClick}>Math Lab</Link></li>
                      <li><Link className="dropdown-item" to="/supw" onClick={handleSubmenuClick}>SUPW</Link></li>
                    </ul>
                  </li>
                  <li><Link className="dropdown-item" to="/classrooms" onClick={handleSubmenuClick}>Classrooms</Link></li>
                  <li><Link className="dropdown-item" to="/multipurposeHall" onClick={handleSubmenuClick}>Multipurpose Hall</Link></li>
                  <li><Link className="dropdown-item" to="/seminarHall" onClick={handleSubmenuClick}>Seminar Hall</Link></li>
                  <li><Link className="dropdown-item" to="/hostel" onClick={handleSubmenuClick}>Hostel</Link></li>
                  <li><Link className="dropdown-item" to="/cafeteria" onClick={handleSubmenuClick}>Cafeteria</Link></li>
                  <li><Link className="dropdown-item" to="/transportation" onClick={handleSubmenuClick}>Transportation</Link></li>
                </ul>
              </li>
              <li
                className="nav-item dropdown"
                onMouseEnter={() => handleMouseEnter('engagementDropdown')}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  id="engagementDropdown"
                  role="button"
                  aria-expanded={activeDropdown === 'engagementDropdown'}
                  onClick={(e) => handleDropdownClick('engagementDropdown', e)}
                >
                  Student Engagement
                </Link>
                <ul className={`dropdown-menu ${activeDropdown === 'engagementDropdown' ? 'show' : ''}`} aria-labelledby="engagementDropdown">
              

                <li><Link className="dropdown-item" to="/scholarships" onClick={handleSubmenuClick}>Scholarships</Link></li>
                  <li><Link className="dropdown-item" to="/valueAddedPrograms" onClick={handleSubmenuClick}>Value Added Programmes</Link></li>
                  <li><Link className="dropdown-item" to="/placementcell" onClick={handleSubmenuClick}>Placement Cell</Link></li>
                  <li><Link className="dropdown-item" to="/guidence" onClick={handleSubmenuClick}>Guidance and Counselling</Link></li>
                 
                  <li><Link className="dropdown-item" to="/studentCouncil" onClick={handleSubmenuClick}>Student Council</Link></li>
                    </ul>
              </li>
             

              <li className="nav-item">
                <Link to="/alumini" className="nav-link" onClick={handleSubmenuClick}>Alumini</Link>
              </li>

              <li
                className="nav-item dropdown"
                onMouseEnter={() => handleMouseEnter('eventsNewsDropdown')}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  id="eventsNewsDropdown"
                  role="button"
                  aria-expanded={activeDropdown === 'eventsNewsDropdown'}
                  onClick={(e) => handleDropdownClick('eventsNewsDropdown', e)}
                >
                  Events & News
                </Link>
                <ul className={`dropdown-menu ${activeDropdown === 'eventsNewsDropdown' ? 'show' : ''}`} aria-labelledby="eventsNewsDropdown">
                  <li><Link className="dropdown-item" to="/upcomingEvents" onClick={handleSubmenuClick}>Upcoming Events</Link></li>
                  <li><Link className="dropdown-item" to="/collegeNews" onClick={handleSubmenuClick}>College Newsletter</Link></li>
                   </ul>
              </li>
              <li
                className="nav-item dropdown"
                onMouseEnter={() => handleMouseEnter('rdDropdown')}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  id="iqacDropdown"
                  role="button"
                  aria-expanded={activeDropdown === 'iqacDropdown'}
                  onClick={(e) => handleDropdownClick('iqacDropdown', e)}
                >
                  IQAC
                </Link>
                <ul className={`dropdown-menu ${activeDropdown === 'iqacDropdown' ? 'show' : ''}`} aria-labelledby="iqacDropdown">
                <li><Link className="dropdown-item" to="/iqac" onClick={handleSubmenuClick}>IQAC
                </Link></li>
                  <li><Link className="dropdown-item" to="/collegePolicy" onClick={handleSubmenuClick}>College Policy Framework
                  </Link></li>
                  <li><Link className="dropdown-item" to="/bestPractices" onClick={handleSubmenuClick}>Best Practices</Link></li>
                  <li><Link className="dropdown-item" to="/academicCalender" onClick={handleSubmenuClick}>Academic Calendar</Link></li>
                  <li><Link className="dropdown-item" to="/plos" onClick={handleSubmenuClick}>PLOs & CLOs</Link></li>
                  <li><Link className="dropdown-item" to="/aqar" onClick={handleSubmenuClick}>AQAR</Link></li>
                </ul>
              </li>
            
              <li className="nav-item">
                <Link to="/longTermPlan" className="nav-link" onClick={handleSubmenuClick}>Long-Term Plan</Link>
              </li>
            
              <li className="nav-item">
                <Link to="/gallery" className="nav-link" onClick={handleSubmenuClick}>Gallery</Link>
              </li>
            
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
