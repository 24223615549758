
import ImageSlider from './ImageSlider'; 


import ScienceLab1 from '../assets/images/facilities/ScienceLab-1.jpg';
import ScienceLab2 from '../assets/images/facilities/ScienceLab-2.jpg';
import ScienceLab3 from '../assets/images/facilities/ScienceLab-3.jpg';

const images = [
    { src: ScienceLab1, altText: 'Integrated Science Lab-1' },
    { src: ScienceLab2, altText: 'Integrated Science Lab-2' },
    { src: ScienceLab3, altText: 'Integrated Science Lab-3' }
];

const IntegratedScienceLab = () => {
    return (
        <section className="ftco-section ftco-no-pt ftco-no-pb"> 
            <div className="container">
                <div className="row justify-content-center mt-3 pb-4">
                    <div className="col-md-12 heading-section ftco-animate text px-4">
                        <h2 className="mb-4 text-success text-center">
                            <span className="text-warning">Integrated </span> Science Lab
                        </h2>   
                    </div>
                </div>
                
                <div className="row mt-3">
                    <div className="col-md-8 order-md-first wrap-about">
                        <div className="text px-4">
                            <p>
                            The Integrated Science Lab at Kaamadhenu College of Education enhances student teachers' understanding of scientific principles through practical experience. The lab integrates concepts from various scientific disciplines, including physics, chemistry, and biology, allowing students to explore and experiment across a broad range of topics.

                            </p>
                            <p>
                            Equipped with modern tools and apparatus, the lab supports a wide range of experiments and hands-on activities. Student teachers engage with scientific phenomena through interactive experiments, data analysis, and observation, which fosters a deeper comprehension of scientific theories and methods. The lab develops student teachers’ critical thinking, problem-solving skills, and scientific inquiry. By bridging theory and practice, it prepares students to apply scientific concepts effectively and builds a strong foundation for future teaching and research in the sciences.  </p>
                        </div>
                    </div>
                    <div className=" col-md-4 order-md-first wrap-about">
                    <ImageSlider images={images} />              
                    </div>
                </div>  
            </div>
            <hr className="custom-hr"/>
        </section>
    );
};

export default IntegratedScienceLab;
